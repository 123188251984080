import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import HeroContent from '../components/HeroContent'
import About from '../components/About'
import Services from '../components/Services'
import Contact from '../components/Contact'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <HeroContent/>
    <About/>
    <Services/>
    <Contact/>
    <Footer/>
  </Layout>
)

export default IndexPage
