import React from 'react'
import styled from 'styled-components'

import fb from '../images/fb.png'
import ig from '../images/ig.png'
import twitter from '../images/twitter.png'

const StyledFooterContainer = styled.footer`
display: flex;
flex-direction: column;
height: 131px;
background: #645F5F;
align-items: center;
justify-content: center;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
 width: 210%;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  width: 160%;
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
 width: 160%;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 width: 150%;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
 width: 100%;
}

`
const StyledIcon = styled.img`
width: 35px;
height: 35px;
margin: 5px;
`
const StyledIconRow = styled.div`
    display:flex;
    margin-bottom: 20px;

`
const StyledText = styled.div`
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 23px;
color: #FFFFFF;
`
const Footer = () => (

    <StyledFooterContainer>
        <StyledIconRow>
            <StyledIcon src={fb} />
            <StyledIcon src={ig} />
            <StyledIcon src={twitter} />
        </StyledIconRow>
        <StyledText>
        © {new Date().getFullYear()} Akas Services Inc. All Rights Reserved.
        </StyledText>
    </StyledFooterContainer>
)

export default Footer;