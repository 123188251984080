import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import bg from '../../images/bg.png'

import Header from '../header'
const StyledContainer =  styled.div`
    display: flex;
    flex:1;
    flex-direction: column;

    background-image: url(${bg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 768px) {
     height: 475px;
     width: 210%;
    }
  
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      width: 160%;
      height: 575px;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
    width: 160%;
     height: 675px;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
     height: 875px;
    width: 150%;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1400px) {
     height: 875px;
    width: 100%;
    }

`

const StyledHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 175px;
  width: 400px;

  margin-right: 120px;
  align-self: flex-end;
  align-items: center;
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 768px) {
    align-self: center;
    margin-right: 0px;
    background: rgb(255, 255, 255, .4);
   
  }


  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  
    align-self: flex-end;
    margin-right: 120px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    align-self: flex-end;
    margin-right: 120px;
  
  }
  

`
const StyledTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 55px;
line-height: 69px;

color: #595656;
`

const StyledSubTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 26px;
line-height: 33px;
color: #595656;
`

const StyledButton = styled(Link)`
text-align: center;
text-decoration: none;
margin-top: 20px;
width: 245px;
height: 50px;
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 50px;
color: #595656;
border: 1px solid #595656;
transition: all 0.8s ease 0s;
cursor: pointer;
&:hover {
    background : #fff;
    border: #fff
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  font-size: 15px;
  width: 140px;
  height: 30px;
  line-height: 30px;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  font-size: 15px;
  width: 160px;
  height: 30px;
  line-height: 30px;
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  font-size: 15px;
  width: 160px;
  height: 30px;
  line-height: 30px;
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  font-size: 15px;
  width: 180px;
  height: 35px;
  line-height: 35px;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  font-size: 16px;
  width: 200px;
  height: 40px;
  line-height: 40px;
}

`

const HeroContent = ()=> (
<StyledContainer>
    <Header/>
<StyledHeroContent>
    <StyledTitle>
        Akas Services
    </StyledTitle>
    <StyledSubTitle>
        Managing and Investings Inc.
    </StyledSubTitle>
    <StyledButton to="/#contact">Contact Us</StyledButton>
</StyledHeroContent>

   </StyledContainer>
)
export default HeroContent