import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
 display: flex;
 flex-direction: column;
 flex:1;

 align-items: center;
 padding-top: 30px;
 background: #E5E5E5;

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 768px) {
  height: 575px;
  width: 210%;
 }

 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
   width: 160%;
   height: 575px;
 }
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
  width: 160%;
  height: 475px;
 }
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  height: 475px;
  width: 150%;
 
 }
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1400px) {
  height: 475px;
  width: 100%;
 
 }
`
const StyledTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: 800;
font-size: 35px;
line-height: 50px;

color: #595656;

`

const StyledText = styled.p`
margin-top: 80px;
font-family: Muli;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 30px;
text-align: justify;
padding: 0px 100px 0px 100px;
color: #595656;
`

const StyledDivider = styled.div`
margin-top: 10px;
width: 60px;
height: 0px;
border: 4px solid #55BAF3;
`
const About = () => (
    <StyledContainer>
     <StyledTitle>About Us</StyledTitle>
     <StyledDivider/>
     <StyledText>
     Akas Services Managing & Investings Inc. is group of professionals specialized in management, engineering and science with a vision to increase efficiency in real estate sector. We work towards reducing costs of housing for families. We serve to reduce waste of resources in building and land projects. Our group has experience and expertise to provide you property and project management services higher than your expectations.
     </StyledText>
    </StyledContainer>
)

export default About