import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 768px) {
     height: 1600px;
     width: 210%;
    }
   
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      width: 160%;
      height: 1500px;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
     width: 150%;
     height: 1300px;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
     height: 1150px;
     width: 150%;
    
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1400px) {
     height: 1150px;
     width: 100%;
    
    }
`
const StyledTitle = styled.div`
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 50px;
    color: #595656;
`

const StyledDivider = styled.div`
    margin-top: 10px;
    width: 60px;
    height: 0px;
    border: 4px solid #55BAF3;
`
const StyledMapContainer = styled.div`
 margin-bottom: 30px;

`
const StyledContactContainer = styled.div`
    display: flex;
    flex:1;
    width: 855px;
   


    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    
        align-items: center;
    }
   
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
  
        flex-direction: row;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {

        flex-direction: row;
    
    }
`

const StyledFormContainer = styled.div`
    flex:1;
    flex-direction: column;

    display:flex;

`

const StyledContactInfoContainer = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
        /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 768px) {
  
    
        padding-left: 40px;
    }
   
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {

        padding-left: 40px;
    }
`

const StyledContactHeading = styled.div`
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 30px;
    color: #595656;
    padding: 10px 0 10px 0px;
`

const StyledContactText = styled.div`
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #595656;
 
    padding ${props => props.paddingRight ? "10px 80px 0px 0px": "10px 10px 0px 0px;"};
    margin-bottom: 20px;
    
`

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

`
    

const StyledTextInput = styled.input`
width: 350px;
height: 40px;
color: #35DBFF;
background: #F3F1F1;
padding-left: 15px;
border: none;
margin: 0px 10px 25px 0px;
`
const StyledTextArea = styled.textarea`
width: 350px;
height: 180px;
left: 306px;
top: 2862px;
font-family: Muli;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 23px;
color: #35DBFF;
background: #F3F1F1;
padding-left: 15px;
padding-top: 10px;
border: none;
margin: 0px 0px 25px 0px;
resize: none;
`

const StyledButton = styled.button`
width: 175px;
height: 45px;
background: #35DBFF;
font-family: Muli;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 25px;
color: #FFFFFF;
text-decoration: none;
border: none;
transition: all 0.8s ease 0s;
cursor: pointer;
&:hover {
  background-color: #fff;
  color: #35DBFF;
  border: 2px solid #35DBFF;
}

`

const StyledIFrame = styled.iframe`
    border:0;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 768px) {
        width:700px;
        height:300px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        width:700px;
        height:300px;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
    
        width:850px;
        height:350px;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
    
        width:850px;
        height:350px;

    }
`

const Contact = () =>(
    <StyledContainer id="contact">
        <StyledTitle>Contact Us</StyledTitle>
        <StyledDivider/>

        <StyledContactContainer>
            <StyledFormContainer>
                <StyledContactHeading>
                    Inquiries
                </StyledContactHeading>
                <StyledContactText paddingRight>
                    Please Email: managing@akas.ca or fill out the form below:
                </StyledContactText>
                <StyledForm 
                    name="contact"
                    method="post"
                    data-netlify-honeypot="bot-field"
                    data-netlify="true"
                    action="/success">

                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <StyledTextInput type="text" name="name" placeholder="Your Name" required/>
                    <StyledTextInput type="email" name="email" placeholder="Your Email" required/>
                    <StyledTextArea name="message" placeholder="Message" required/>
                    <StyledButton>Submit</StyledButton>
                </StyledForm>
  

            </StyledFormContainer>
            <StyledContactInfoContainer>
                <StyledContactHeading>
                    Office
                </StyledContactHeading>
                <StyledContactText>
                    25 Sheppard Avenue West, Suite 300 Toronto, ON M2N 6S6
                </StyledContactText>
                <StyledContactHeading>
                    Special Deals
                </StyledContactHeading>
                <StyledContactText>
                    Discount up to 10% on rentals of Studio, 1 bedroom and 2 bedroom apartments.
                </StyledContactText>
                <StyledContactText>
                    Email us to obtain information on Akas Services Gift Card.
                </StyledContactText>
                <StyledContactHeading>
                Job Opportunities
                </StyledContactHeading>
                <StyledContactText>
                We have jobs for committed, diligent skilled workers. Email us your resume. Our Human Resource manager will contact you if your skill and experience matches our requirements.
                </StyledContactText>
            </StyledContactInfoContainer>
        </StyledContactContainer>
        <StyledMapContainer>
         <StyledIFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1312.838537115412!2d-79.41281308056674!3d43.761013802646495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2d63630f7429%3A0x8e8c1b22ab5ac319!2s25%20Sheppard%20Ave%20W%2C%20North%20York%2C%20ON%20M2N%206S6!5e0!3m2!1sen!2sca!4v1576426739259!5m2!1sen!2sca"  frameborder="0" ></StyledIFrame>
        </StyledMapContainer>
    </StyledContainer>
)

export default Contact;