import React from 'react'
import styled from 'styled-components'
import card1Image from '../../images/card1Image.png'
import card2Image from '../../images/card2Image.png'

const StyledContainer = styled.div`

background: #F2EDED;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 30px;
padding-left: 100px;
padding-right: 100px;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
    height: 1400px;
    width: 210%;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    width: 160%;
    height: 1400px;
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1200px) {
    width: 150%;
    height: 1200px;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
    width: 100%;
    height: 800px;
  
}

`

const StyledTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: 800;
font-size: 35px;
line-height: 50px;
color: #595656;
`

const StyledDivider = styled.div`
margin-top: 10px;
width: 60px;
height: 0px;
border: 4px solid #55BAF3;
`

const StyledCardsContainer = styled.div`
 display: flex;
 flex:1;
 width: 100%;
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 768px) {
  flex-direction: column;
  
  
 }

 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
    flex-direction: column;
 }
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 1200px) {

    flex-direction: column;
 
 }
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1400px) {

    flex-direction: row;
   
 }
`
const StyledCardBox = styled.div`
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;

`
const StyledCard = styled.div`
  height: 480px;
  width: 480px;

  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
`
const StyledCardImage = styled.img`
  width: 100%;
  height: 50%;
  margin-bottom: -10px;
`

const StyledCardContent = styled.div`
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
    height: 50%;

`

const StyledCardTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 30px;
color: #595656;`

const StyledCardText = styled.div`
font-family: Muli;
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 25px;
color: #595656;
text-align: left;
padding-left: 40px;
padding-right: 40px;
padding-top: 10px;
`
const Services = () =>(

    <StyledContainer>
        <StyledTitle>Services</StyledTitle>
        <StyledDivider/>
        <StyledCardsContainer>
            <StyledCardBox>
    
                <StyledCard>
                    <StyledCardImage src={card1Image}/>
                    <StyledCardContent>
                        <StyledCardTitle>
                            Property Management
                        </StyledCardTitle>
                        <StyledCardText>
                        <p>We are experts in residential building management. We manage properties at costs as low as 7%.</p>
                        </StyledCardText>
                    </StyledCardContent>
      
                </StyledCard>         
            </StyledCardBox>
                    
            <StyledCardBox>
                <StyledCard>
                    <StyledCardImage src={card2Image}/>
                    <StyledCardContent>
                        <StyledCardTitle>
                            Project Management
                        </StyledCardTitle>
                        <StyledCardText>
                        We manage mostly all stages of building projects bringing you peace of mind, profit and satisfaction.
                        </StyledCardText>
                    </StyledCardContent>
                </StyledCard>

                        
            </StyledCardBox>
        </StyledCardsContainer>
    </StyledContainer>
)

export default Services;